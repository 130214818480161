/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
import throttle from 'lodash/throttle';
import { AnnouncementsV2 } from '~/globals/announcements-v2';
import { SiteNavAutocomplete } from '~/globals/site-nav-autocomplete';
import { expandElement, collapseElement } from '~/shared/utils/expand-collapse';
import { onNextRepaint } from '~/shared/utils/animation';

// should match 'medium' value in `app/assets/stylesheets/site-nav/_mixins.scss`
const isNavSmallScreen = () => !window.matchMedia('(min-width: 790px)').matches;
// should match 'large' value in `app/assets/stylesheets/site-nav/_mixins.scss`
const isNavMediumScreen = () => !window.matchMedia('(min-width: 1205px)').matches;

const updateBodyScrollLock = () => {
  const isMobileMenuOpen = document.body.hasAttribute('data-site-nav-mobile-menu-open');
  const isPopoverOpen = !!document.querySelector('[data-site-nav-popover-toggled]');
  document.body.toggleAttribute('data-site-nav-lock-scroll', isMobileMenuOpen || isPopoverOpen);
};

export const SiteNav = {
  init({ isFramer = false } = {}) {
    this.bindStickySearch();
    this.bindMobileMenu();
    this.setupAutocompleteSearch();
    this.bindMobilePopovers();
    AnnouncementsV2.init();

    if (isFramer) {
      this.fadeInDynamicContent('site-nav');
      this.fadeInDynamicContent('site-footer');
    }
  },

  setupAutocompleteSearch() {
    const autocompleteContainers = document.querySelectorAll('[data-nav-autocomplete-container]');

    autocompleteContainers.forEach((container) => {
      new SiteNavAutocomplete(container);
    });
  },

  bindStickySearch() {
    // toggle `data-site-nav-scrolled` body attribute based on scroll position
    const throttledScrollCheck = throttle(() => {
      const hasScrolled = window.scrollY > 40;
      document.body.toggleAttribute('data-site-nav-scrolled', hasScrolled);
    }, 20);

    document.addEventListener('scroll', throttledScrollCheck, { passive: true });

    // toggle `data-site-nav-search-btn` when opening/closing the search
    const searchBtn = document.querySelector('[data-site-nav-search-btn]');

    searchBtn?.addEventListener('click', () => {
      document.body.toggleAttribute('data-site-nav-search-open');
    });
  },

  bindMobileMenu() {
    const burger = document.querySelector('[data-site-nav-burger]');
    if (!burger) return;

    const toggleMobileMenu = (force = undefined) => {
      document.body.toggleAttribute('data-site-nav-mobile-menu-open', force);
      updateBodyScrollLock();
    };

    // toggle mobile menu when clicking hamburger button
    burger.addEventListener('click', (e) => {
      e.preventDefault();
      toggleMobileMenu();
    });

    // expand/collapse mobile menu items
    const subNavTriggers = document.querySelectorAll('[data-site-nav-sub-trigger]');

    subNavTriggers.forEach((subNavTrigger) => {
      subNavTrigger.addEventListener('click', (e) => {
        if (!isNavMediumScreen()) return;

        e.preventDefault();
        const subNav = subNavTrigger.nextElementSibling;
        const isExpanded = subNav.hasAttribute('data-expanded');

        if (isExpanded) {
          collapseElement(subNav);
        } else {
          expandElement(subNav);
        }
      });
    });

    // close mobile menu when clicking outside of it (ignoring burger)
    document.body.addEventListener('click', (e) => {
      const clickedBurger = e.target.closest('[data-site-nav-burger]');
      const clickedInsideNav = e.target.closest('[data-site-nav-main]');
      if (clickedBurger || clickedInsideNav) return;

      toggleMobileMenu(false);
    });
  },

  bindMobilePopovers() {
    const popoverTriggers = document.querySelectorAll('[data-site-nav-popover-trigger]');

    const togglePopover = (popoverEl, force = undefined) => {
      popoverEl.toggleAttribute('data-site-nav-popover-toggled', force);
      updateBodyScrollLock();
    };

    popoverTriggers.forEach((popoverTriggerEl) => {
      const popoverEl = popoverTriggerEl.nextElementSibling;
      if (!popoverEl) return;

      // toggle popover when clicking trigger
      popoverTriggerEl.addEventListener('click', (e) => {
        if (!isNavSmallScreen()) return;

        e.preventDefault();
        togglePopover(popoverEl);
      });

      // close popover when clicking outside of it (ignoring trigger)
      document.body.addEventListener('click', (e) => {
        const clickedTrigger = popoverTriggerEl.contains(e.target) || e.target === popoverTriggerEl;
        const clickedInsidePopover = popoverEl.contains(e.target) && e.target !== popoverEl;
        if (clickedTrigger || clickedInsidePopover) return;

        togglePopover(popoverEl, false);
      });
    });
  },

  fadeInDynamicContent(name) {
    const element = document.querySelector(`[data-${name}]`);
    if (!element) return;

    // delay by a frame to ensure transition is triggered
    onNextRepaint(() => {
      element.classList.add(`${name}--loaded`);
    });
  },

  // toggle search form visibility on special pages (e.g. homepage shot feed)
  enableHiddenSearchMode(viewportElement) {
    if (!viewportElement) return;

    // setup intersection observer to toggle search form visibility
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const isElementAboveViewport = !entry.isIntersecting && entry.boundingClientRect.top < 0;
        document.body.setAttribute('data-site-nav-hide-search', !isElementAboveViewport);
      });
    }, {
      root: null,
      threshold: [0],
    });

    observer.observe(viewportElement);
  },
};
